import request from '@/utils/request'

export function storeList (data) {
    return request({
        url: '/store/list',
        method: 'get',
        params: data
    })
}
export function storeBan (data) {
    return request({
        url: '/store/is_ban',
        method: 'post',
        data
    })
}
export function storeAdd (data) {
    return request({
        url: '/store/add',
        method: 'post',
        data
    })
}
export function storeDel (data) {
    return request({
        url: '/store/del',
        method: 'post',
        data
    })
}
export function storeInfo (data) {
    return request({
        url: '/store/info',
        method: 'get',
        params: data
    })
}
export function storeUpdate (data) {
    return request({
        url: '/store/update',
        method: 'post',
        data
    })
}
export function storeSetup (data) {
    return request({
        url: '/store/setup_oem',
        method: 'post',
        data
    })
}
export function storeGetin (data) {
    return request({
        url: '/store/get_into',
        method: 'post',
        data
    })
}


export function storeSynchro(data) {
    return request({
        url: '/store/synchro',
        method: 'post',
        data
    })
}

export function storeUpdateScnum(data) {
    return request({
        url: '/store/update_scnum',
        method: 'post',
        data
    })
}

export function mtAmountList(data) {
    return request({
        url: '/store/mtAmountList',
        method: 'post',
        data
    })
}

export function getMtAmount(data) {
    return request({
        url: '/store/getMtAmount',
        method: 'post',
        data
    })
}

export function mtAmountHandle(data) {
    return request({
        url: '/store/mtAmountHandle',
        method: 'post',
        data
    })
}

export function getPlugin(data) {
    return request({
        url: '/store/getPlugin',
        method: 'post',
        data
    })
}